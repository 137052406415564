/* eslint-disable consistent-return */
import { useEffect, useMemo, useState } from 'react';

import { DESKTOP_FIRST_MEDIA, MOBILE_FIRST_MEDIA } from '@/shared/lib/media';

import { isClient } from '../lib/helpers/predicates';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export const useIsXs = () => useMediaQuery(MOBILE_FIRST_MEDIA.XS);
export const useIsSm = () => useMediaQuery(MOBILE_FIRST_MEDIA.SM);
export const useIsMd = () => useMediaQuery(MOBILE_FIRST_MEDIA.MD);
export const useIsLg = () => useMediaQuery(MOBILE_FIRST_MEDIA.LG);
export const useIsXl = () => useMediaQuery(MOBILE_FIRST_MEDIA.XL);
export const useIsXxl = () => useMediaQuery(MOBILE_FIRST_MEDIA.XXL);

export const useIsDesktopXl = () => useMediaQuery(DESKTOP_FIRST_MEDIA.XL);

export const useIsMobile = () => useMediaQuery(DESKTOP_FIRST_MEDIA.MD);
export const useIsTablet = () => useMediaQuery(DESKTOP_FIRST_MEDIA.LG);
export const useIsDesktop = () => useMediaQuery(MOBILE_FIRST_MEDIA.XL);
export const useIsLargeDesktop = () => useMediaQuery(MOBILE_FIRST_MEDIA.XXL);

export const useBreakpoint = () => {
  const xs = useIsXs();
  const sm = useIsSm();
  const md = useIsMd();
  const lg = useIsLg();
  const xl = useIsXl();
  const xxl = useIsXxl();

  return useMemo(
    () => Object.entries({ xs, sm, md, lg, xl, xxl }).flatMap(([key, value]) => (value ? [key] : [])),
    [xs, sm, md, lg, xl, xxl]
  );
};

export const useViewport = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLargeDesktop = useIsLargeDesktop()

  return useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
      isLargeDesktop
    }),
    [isMobile, isTablet, isDesktop, isLargeDesktop]
  );
};

export default useMediaQuery;
