import { NextPageContext } from 'next';
import queryString, { ParseOptions, StringifyOptions } from 'query-string';

import { GetSrsOptions, PictureSourceSet } from '@/shared/types';

import { NEXT_PUBLIC_CDN_ROOT } from '../../../../../cfg';

const DEF_STRINGIFY_OPTIONS: StringifyOptions = {
  arrayFormat: 'comma',
  skipNull: true,
  skipEmptyString: true,
  encode: false,
};

const DEF_PARSE_OPTIONS: ParseOptions = { arrayFormat: 'comma', parseNumbers: true, parseBooleans: true };

export const createUrl = (baseUrl: string, query?: Record<string, any>) => {
  const url = new URL(baseUrl);

  if (!query) {
    return url.toString();
  }

  Object.entries(query).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    url.searchParams.append(key, value);
  });

  return url.toString();
};

export const stringifyQuery = (query: Record<string, any>, options?: StringifyOptions) =>
  queryString.stringify(query, options ?? DEF_STRINGIFY_OPTIONS);

export const parseQuery = (query: string, options?: ParseOptions) =>
  queryString.parse(query, options ?? DEF_PARSE_OPTIONS);

export const identifyHost = (req: NextPageContext['req']) => {
  if (!req) {
    throw new Error('Request is not defined');
  }

  const { host = '' } = req.headers;

  const [subdomain] = host ? host.split('.') : [];

  return { host, subdomain };
};

export const getSrc = (source?: Partial<PictureSourceSet> | string, options?: GetSrsOptions) => {
  if (!source) {
    return;
  }

  const host = options?.host ?? NEXT_PUBLIC_CDN_ROOT

  if (typeof source === 'string') {
    return `${host}${source}`;
  }

  const { url, density } = source as Partial<PictureSourceSet>;

  return `${host}${url}${density ? ` ${density}` : ''}`;
};
